<script setup lang="ts">
interface Props {
  progress: number
  title?: string
  contentClass?: string
  textAlignment?: 'text-left' | 'text-center' | 'text-right'
  type?: string
  tooltip?: {
    placement?: 'top' | 'right' | 'bottom' | 'left'
    content?: string
    disabled?: boolean
  }
}

const props = withDefaults(defineProps<Props>(), {
  progress: undefined,
  title: undefined,
  contentClass: '',
  textAlignment: 'text-left',
  type: 'fields',
  tooltip: undefined,
})

const content = computed(() => {
  if (props.tooltip?.disabled) {
    return undefined
  }
  if (props.tooltip?.content) {
    return props.tooltip.content
  }
  return `${Math.round(props.progress)}% of the ${props.type} were approved`
})

const outerClass = computed(() => {
  if (!props.tooltip?.disabled) {
    return 'cursor-help w-full'
  }
  return 'w-full'
})
</script>

<template>
  <VTooltip
    class="w-full"
    :delay="100"
    :distance="8"
    :auto-hide="false"
    :placement="tooltip?.placement ?? 'bottom'"
  >
    <!-- Progress -->
    <div
      class="-mt-1 flex flex-col text-[10px]"
      :class="[contentClass, textAlignment, outerClass]"
    >
      <span class="">
        {{ title }}
      </span>
      <div
        class="bg-gray-650 relative flex h-1 w-full overflow-hidden rounded-full"
      >
        <div
          class="absolute bottom-0 left-0 top-0"
          role="progressbar"
          aria-valuemin="0"
          aria-valuemax="100"
          :aria-valuenow="Math.floor(progress ?? 0)"
          :aria-valuetext="`Lease completion: ${Math.floor(progress ?? 0)}%`"
          :aria-label="`Lease completion: ${Math.floor(progress ?? 0)}%`"
          :class="[
            progress && progress >= 100
              ? 'bg-success'
              : progress && progress >= 30
                ? 'bg-warning'
                : 'bg-error',
          ]"
          :style="{
            width: Math.max(progress ?? 0, 6) + '%',
          }"
        ></div>
      </div>
    </div>

    <!-- tooltip -->
    <template #popper>
      <div class="text-xs">
        {{ content }}
      </div>
    </template>
  </VTooltip>
</template>
